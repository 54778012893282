import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

import App from "./App";
import Spinner from "./components/utils/Spinner";

import "./index.css";
import AntdProvider from "./layout/AntdProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Suspense fallback={<Spinner />}>
        <BrowserRouter>
            <AntdProvider>
                <App />
            </AntdProvider>
        </BrowserRouter>
    </Suspense>
);
