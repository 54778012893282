export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const EDIT_USER = "EDIT_USER";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const ACTIVATE_USER = "ACTIVATE_USER";
export const CHANGE_USER_SUBSCRIPTION = "CHANGE_USER_SUBSCRIPTION";
export const DELETE_USER_ACCOUNT = "DELETE_USER_ACCOUNT";

export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const EDIT_PROFILE = "EDIT_PROFILE";

export const SAVE_TRANSACTION = "SAVE_TRANSACTION";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";
