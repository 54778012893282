import { SAVE_TRANSACTION, TRANSACTION_ERROR } from "../actions/types";

const initialState = {
    transaction: null,
    transactions: [],
    repos: [],
    loading: true,
    error: {}
}

export default function paymentTransactionReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case SAVE_TRANSACTION:
            return {
                ...state,
                program: payload,
                loading: false
            }
        
        case TRANSACTION_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }

        default:
            return state;
    }
}