import { ConfigProvider, Spin } from "antd";
import React from "react";
import frFR from "antd/locale/fr_FR";
import Spinner from "../components/utils/Spinner";
import LayoutModal from "./LayoutModal";
import LayoutNotifications from "./LayoutPopup";
// import configTheme from "./theme.json";

Spin.setDefaultIndicator(
    // <Spinner isSmall widthClass=" w-6" heightClass=" h-6" />
    <Spinner isSmall />
);

// ...
const AntdProvider = ({ children }) => {
    return (
        <ConfigProvider
            locale={frFR}
            theme={{
                cssVar: true,
                token: {
                    // INCASE : antd theme from json
                    // ...configTheme,
                    colorPrimary: "#0038cb",
                    colorInfo: "#0038cb",
                    colorSuccess: "#3ce1b9",
                    // colorWarning: "#fff500",
                    colorError: "#ff0047",
                    // colorBgBase: "#FAFBFD", // "#fafbfd",
                    // colorTextBase: "#010527",
                    fontFamily: "Montserrat",
                },
                components: {
                    Button: {
                        // borderRadius: 2,
                        // borderRadiusLG: 4,
                        fontWeight: 700,
                    },
                },
            }}
        >
            <LayoutNotifications>
                <LayoutModal>{children}</LayoutModal>
            </LayoutNotifications>
        </ConfigProvider>
    );
};

export default AntdProvider;
