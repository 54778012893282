import { Form, Modal, theme } from "antd";
import { createContext, useContext, useEffect, useState } from "react";
import { PopupContext } from "./LayoutPopup";

export const ModalContext = createContext(null);

const LayoutModal = ({ children }) => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const { startDriveSyncMessage } = useContext(PopupContext);

    const [modalData, setModalData] = useState({
        title: <></>,
        content: null,
        props: {},
        // method : "ASYNC" | "BLOCK"
    });
    const isForm = true;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [okFunction, setOkFunction] = useState();
    const [isConfirmDisabled, setIsConfirmDisabled] = useState();

    useEffect(() => {
        if (modalData.content) {
            setModalOpen(true);
        }
    }, [modalData.content]);

    const values = Form.useWatch([], form);
    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsConfirmDisabled(false))
            .catch(() => setIsConfirmDisabled(true));
    }, [values, form]);

    return (
        <ModalContext.Provider
            value={{ modalData, setModalData, setOkFunction }}
        >
            {children}
            <Modal
                {...modalData.props}
                styles={{
                    content: {
                        padding: "0",
                    },
                    header: {
                        padding: token.padding,
                        borderBottom: `1px solid ${token.colorBorder}`,
                    },
                    body: {
                        padding: token.paddingLG,
                        maxHeight: "70vh",
                        overflow: "auto",
                    },
                    footer: {
                        padding: token.padding,
                        // borderTop: "1px solid #e9e9e9",
                    },
                }}
                title={modalData.title}
                open={modalOpen}
                onCancel={async () => {
                    // if (modalData.handleCancel) await modalData.handleCancel();
                    setModalOpen(false);
                }}
                okButtonProps={{
                    autoFocus: true,
                    htmlType: "submit",
                    disabled: isForm ? isConfirmDisabled : false,
                }}
                cancelButtonProps={{
                    variant: "outlined",
                }}
                onOk={async () => {
                    if (modalData.method === "ASYNC") {
                        startDriveSyncMessage();
                        setModalOpen(false);
                        if (okFunction) await okFunction();
                    } else {
                        setLoading(true);
                        if (okFunction) await okFunction();
                        setModalOpen(false);
                        setLoading(false);
                    }
                }}
                confirmLoading={loading}
                destroyOnClose
                centered
                modalRender={(dom) =>
                    isForm ? (
                        <Form form={form} name="formModal" clearOnDestroy>
                            {dom}
                        </Form>
                    ) : (
                        dom
                    )
                }
            >
                {modalData.content}
            </Modal>
        </ModalContext.Provider>
    );
};

export default LayoutModal;

//    /** Whether to apply loading visual effect for OK button or not */
//    confirmLoading?: boolean;
//    /** Specify a function that will be called when a user clicks the OK button */
//    onOk?: (e: React.MouseEvent<HTMLButtonElement>) => void;
//    /** Specify a function that will be called when a user clicks mask, close button on top right or Cancel button */
//    onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void;
//    afterClose?: () => void;
//    /** Callback when the animation ends when Modal is turned on and off */
//    afterOpenChange?: (open: boolean) => void;
//    /** Centered Modal */
//    centered?: boolean;
//    /** Width of the modal dialog */
//    width?: string | number;
//    /** Text of the OK button */
//    okText?: React.ReactNode;
//    /** Button `type` of the OK button */
//    okType?: LegacyButtonType;
//    /** Text of the Cancel button */
//    cancelText?: React.ReactNode;
//    /** Whether to close the modal dialog when the mask (area outside the modal) is clicked */
//    maskClosable?: boolean;
//    destroyOnClose?: boolean;
//    zIndex?: number;
//    keyboard?: boolean;
//    closeIcon?: React.ReactNode;
