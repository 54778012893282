import axios from "axios";
import i18n from "i18next";

import apiURL from "../utils/apiURL";

export async function getSettingByCode(code) {
    try {
        const setting = await axios.get(apiURL.getSettingByCode + code);

        if (setting.data) return setting.data["value" + i18n.locale];
        else return null;
    } catch (err) {
        return null;
    }
}
