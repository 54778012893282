import axios from 'axios';

const setAuthToken = token => {
    if(token) {
        axios.defaults.headers.common['x-auth-token'] = token;
    } else {
        delete axios.defaults.headers.common['x-auth-token'];
    }
};

//Permet de définir le token de connexion sur l'ensemble des pages de l'application

export default setAuthToken;