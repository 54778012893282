import Cookies from "js-cookie";

export function isCookieEnabled(code) {
    if (
        Cookies.get("CookieConsent") === "true" &&
        !localStorage.getItem("cookiesPreferences")
    )
        return true; // Tous les cookies sont acceptés
    else if (
        Cookies.get("CookieConsent") === "true" &&
        localStorage.getItem("cookiesPreferences")
    ) {
        const cookiesPreferences = JSON.parse(
            localStorage.getItem("cookiesPreferences")
        );

        if (cookiesPreferences[code]) return true; // Cookie accepté
    }

    return false;
}
