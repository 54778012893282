import axios from "axios";
import setAuthToken from "../helpers/setAuthToken";
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from "./types";

const ApiURL = require("../utils/apiURL");

// Load User

export const loadUser = () => async (dispatch) => {
    // On définit le token dans le header

    if (localStorage.token) setAuthToken(localStorage.token);

    try {
        const res = await axios.get(ApiURL.authUser);

        // Thème sélectionné par l'utilisateur

        if (res.data) {
            if (res.data.colorTheme)
                localStorage.setItem("colorTheme", res.data.colorTheme);

            dispatch({
                type: USER_LOADED,
                payload: res.data,
            });
        } else {
            dispatch({
                type: AUTH_ERROR,
            });
        }
    } catch (error) {
        dispatch({
            type: AUTH_ERROR,
        });
    }
};

// Register User

export const register = (newUserDatas) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const body = JSON.stringify(newUserDatas);

    try {
        const res = await axios.post(ApiURL.saveUser, body, config);

        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data,
        });

        return res;
    } catch (error) {
        dispatch({
            type: REGISTER_FAIL,
        });

        return null;
    }
};

// Login User

export const login = (email, password) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const body = JSON.stringify({ email, password });

    try {
        const res = await axios.post(ApiURL.authUser, body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
        });

        dispatch(loadUser());

        return true;
    } catch (e) {
        const errors = e.response.data.errors;

        if (errors) {
            //On affiche une alerte pour toutes les érreurs possibles

            return { errors: errors };
        }

        dispatch({
            type: LOGIN_FAIL,
        });
    }
};

// Logout

export const logout = () => (dispatch) => {
    dispatch({
        type: LOGOUT,
    });
};
