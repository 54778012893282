import React, { createContext } from "react";
import { message, notification } from "antd";
import { useTranslation } from "react-i18next";

export const PopupContext = createContext(null);

const LayoutPopup = ({ children }) => {
    const { t } = useTranslation();

    const [notificationApi, notificationRender] =
        notification.useNotification();

    const [messageApi, messageRender] = message.useMessage();

    const startDriveSyncMessage = () => {
        messageApi.loading({
            key: "syncDrive",
            content: t("attenteDeSynchronisation"),
        });
    };

    const endDriveSyncMessage = () => {
        messageApi.success({
            key: "syncDrive",
            content: t("drive") + " " + t("misAJour"),
            duration: 2,
        });
    };

    const toastAlert = () => {
        // notificationApi.info({
        //     message: `Notification `,
        //     description: <div>ddddd</div>,
        //     placement: "bottomRight",
        // });
    };

    const runNotification = ({ type = "info", title, content }) => {
        notificationApi.open({
            message: title,
            description: content,
            placement: "bottomLeft",
            type,
        });
    };
    const runMessage = () => {};

    return (
        <PopupContext.Provider
            value={{
                startDriveSyncMessage,
                endDriveSyncMessage,

                notificationApi,
                messageApi,
                // Common
                toastAlert,
                runNotification,
                runMessage,
            }}
        >
            {notificationRender}
            {messageRender}
            {children}
        </PopupContext.Provider>
    );
};
export default LayoutPopup;
