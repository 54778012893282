import React, { useEffect, useState, lazy } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { Provider } from "react-redux";
import { store } from "./store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import i18n from "i18next";

import setAuthToken from "./helpers/setAuthToken";
import { loadUser } from "./actions/auth";
import { getSettingByCode } from "./helpers/setting";
import { isCookieEnabled } from "./helpers/cookie";
import "./App.scss";

const HomePage = lazy(() => import("./components/home/HomePage"));
const LandingPage = lazy(() => import("./components/landing/LandingPage"));
const PrivateRoute = lazy(() => import("./components/routing/PrivateRoute"));
const Register = lazy(() => import("./components/register/Register"));
const RegisterSuccess = lazy(() =>
    import("./components/register/RegisterSuccess")
);
const RegisterError = lazy(() => import("./components/register/RegisterError"));
const ResetPassword = lazy(() => import("./components/user/ResetPassword"));
const ChangePassword = lazy(() => import("./components/user/ChangePassword"));
const ActivateAccount = lazy(() => import("./components/user/ActivateAccount"));

const Subscriptions = lazy(() =>
    import("./components/subscriptions/Subscriptions")
);
const SubscriptionError = lazy(() =>
    import("./components/subscriptions/SubscriptionError")
);

const Contact = lazy(() => import("./components/contact/Contact"));

const Institutional = lazy(() =>
    import("./components/institutional/Institutional")
);

const Roadmap = lazy(() => import("./components/roadmap/Roadmap"));

const PageNotFound = lazy(() => import("./components/utils/PageNotFound"));
const MaintenancePage = lazy(() =>
    import("./components/utils/MaintenancePage")
);

const Drive = lazy(() => import("./components/drive/Drive"));

const Viewer = lazy(() =>
    import(/* webpackPrefetch: true */ "./components/viewer/Viewer")
);

const ViewDocument = lazy(() => import("./components/documents/ViewDocument"));

const JoinProject = lazy(() => import("./components/project/JoinProject"));

const CookiesBanner = lazy(() => import("./components/cookie/CookiesBanner"));

const Profile = lazy(() => import("./components/profile/Profile"));

const Account = lazy(() => import("./components/account/Account"));

const IntercomChat = lazy(() => import("./components/utils/IntercomChat"));

const OAuthCallback = lazy(() => import("./components/bcf/OAuthCallback"));

const Constants = require("./utils/constants");
// Pour l'env TEST, on demande un mot de passe

if (
    process.env.REACT_APP_NODE_ENV === "test" &&
    process.env.REACT_APP_PASS_TEST &&
    process.env.REACT_APP_PASS_TEST !== ""
) {
    if (Cookies.get("PASS_TEST") !== process.env.REACT_APP_PASS_TEST) {
        let pass = prompt("Password");

        while (pass !== process.env.REACT_APP_PASS_TEST) {
            pass = prompt("Password");
        }

        // Si c'est le bon mot de passe, on l'enregistre pendant 30 jours

        Cookies.set("PASS_TEST", pass, { expires: 30 });
    }
}

// Si l'utilisateur est connecté

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

// Google Analytics

const initGA = (isGranted = false) => {
    try {
        ReactGA.initialize(Constants.googleAnalyticsTrackingId);

        // Consent mode (sans acceptation nécessaire)
        const consentState = isGranted ? "granted" : "denied";

        window.gtag("consent", isGranted ? "update" : "default", {
            ad_storage: consentState,
            ad_user_data: consentState,
            ad_personalization: consentState,
            analytics_storage: consentState,
        });
    } catch (err) {}
};

/*const initGTM = () => {
    const tagManagerArgs = {
        gtmId: "GTM-TXTFWJ5",
    };
    TagManager.initialize(tagManagerArgs);
};*/

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    const [cookiesPreferences, setCookiesPreferences] = useState(
        localStorage.getItem("cookiesPreferences")
    );

    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV === "production")
            initGA(isCookieEnabled("STATISTICS"));

        /*if (
            isCookieEnabled("MARKETING") &&
            process.env.REACT_APP_NODE_ENV === "production"
        ) {
            initGTM();
        }*/
    }, [cookiesPreferences]);

    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV === "production")
            ReactGA._gaCommandSendPageview(location.pathname + location.search);
    }, [location]);

    // Hotjar

    useEffect(() => {
        if (
            process.env.REACT_APP_NODE_ENV === "production" &&
            isCookieEnabled("STATISTICS")
        ) {
            if (location.pathname === "/drive") {
                const script = document.createElement("script");
                script.async = true;
                script.innerHTML = `(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:5199584,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
                document.head.appendChild(script);
            }

            return () => {
                const hotjarScript = document.querySelector(
                    'script[src^="https://static.hotjar.com"]'
                );
                if (hotjarScript) {
                    document.head.removeChild(hotjarScript);
                }
            };
        }
    }, [location.pathname]);

    // Gestion du mode maintenance de l'application

    const [maintenanceMode, setMaintenanceMode] = useState(false);

    useEffect(() => {
        store.dispatch(loadUser());

        const getMaintenanceMode = async () => {
            if ((await getSettingByCode("MAINTENANCE_MODE")) === "1")
                setMaintenanceMode(true);
            else setMaintenanceMode(false);
        };

        getMaintenanceMode();
    }, []);

    // Gestion du thème via le localStorage

    const theme = localStorage.getItem("colorTheme")
        ? localStorage.getItem("colorTheme")
        : "light";

    useEffect(() => {
        if (theme === "dark")
            document.getElementsByTagName("body")[0].classList.add("dark");
        else if (theme === "light")
            document.getElementsByTagName("body")[0].classList.remove("dark");
    }, [theme]);

    // Langues disponibles en préfix dans l'URL (vide pour EN)
    const prefixLanguages = ["", "fr"];

    // Routes qui sont accessibles avec un préfix de langue dans l'URL
    const multiLanguagesRoutes = [
        {
            url: "/",
            element: <HomePage />,
        },
        {
            url: "/home",
            element: <HomePage />,
        },
        {
            url: "/home/drive",
            element: <LandingPage code="DRIVE" />,
        },
        {
            url: "/home/viewer",
            element: <LandingPage code="VIEWER" />,
        },
        /*{
            url: "/home/transfer",
            element: <LandingPage code="TRANSFER" />,
        },*/
        {
            url: "/register",
            element: <Register />,
        },
        {
            url: "/subscriptions",
            element: <Subscriptions />,
        },
        {
            url: "/contact",
            element: <Contact />,
        },
        {
            url: "/legal-notice",
            element: <Institutional code="LEGAL_NOTICE" />,
        },
        {
            url: "/privacy-policy",
            element: <Institutional code="PRIVACY_POLICY" />,
        },
        {
            url: "/general-conditions",
            element: <Institutional code="GENERAL_CONDITIONS" />,
        },
        {
            url: "/roadmap",
            element: <Roadmap />,
        },
    ];

    // Gestion du préfix de la langue dans l'URL pour certaines routes (SEO)
    // On force la traduction dans la langue du préfix URL
    useEffect(() => {
        const langParam = window.location.pathname.split("/")[1];

        if (
            langParam !== "" &&
            prefixLanguages.includes(langParam) &&
            localStorage.getItem("i18nextLng") !== langParam.toUpperCase()
        ) {
            i18n.changeLanguage(langParam.toUpperCase());
            window.location.reload();
        }
    }, [navigate]);

    return (
        <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
                <div className="App">
                    {maintenanceMode ? (
                        <MaintenancePage />
                    ) : (
                        <Routes>
                            {prefixLanguages.map((language) =>
                                multiLanguagesRoutes.map((route, i) => (
                                    <Route
                                        key={i}
                                        exact
                                        path={language + route.url}
                                        element={route.element}
                                    />
                                ))
                            )}

                            <Route
                                exact
                                path="/register-success"
                                element={<RegisterSuccess />}
                            ></Route>
                            <Route
                                exact
                                path="/register-error"
                                element={<RegisterError />}
                            ></Route>

                            <Route
                                exact
                                path="/reset-password"
                                element={<ResetPassword />}
                            ></Route>
                            <Route
                                exact
                                path="/change-password/:token"
                                element={<ChangePassword />}
                            ></Route>

                            <Route
                                exact
                                path="/activate-account"
                                element={<ActivateAccount />}
                            ></Route>
                            <Route
                                exact
                                path={"/activate-account/:token"}
                                element={<ActivateAccount />}
                            />

                            <Route
                                exact
                                path={"/subscriptions-error"}
                                element={<SubscriptionError />}
                            />

                            {["/drive", "/drive/project/:id", "/portal"].map(
                                (path, i) => (
                                    <Route
                                        key={i}
                                        exact
                                        path={path}
                                        element={
                                            <PrivateRoute>
                                                <Drive />
                                            </PrivateRoute>
                                        }
                                    />
                                )
                            )}

                            {["/timesheet", "/timesheet/:route"].map(
                                (path, i) => (
                                    <Route
                                        key={i}
                                        exact
                                        path={path}
                                        element={
                                            <PrivateRoute>
                                                <Drive toolView="timesheet" />
                                            </PrivateRoute>
                                        }
                                    />
                                )
                            )}

                            {["/automations", "/automations/:route"].map(
                                (path, i) => (
                                    <Route
                                        key={i}
                                        exact
                                        path={path}
                                        element={
                                            <PrivateRoute>
                                                <Drive toolView="automations" />
                                            </PrivateRoute>
                                        }
                                    />
                                )
                            )}

                            <Route
                                exact
                                path="/visas"
                                element={
                                    <PrivateRoute>
                                        <Drive toolView="visas" />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                exact
                                path="/statistics"
                                element={
                                    <PrivateRoute>
                                        <Drive toolView="statistics" />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                exact
                                path="/join-project/:id/:token"
                                element={<JoinProject />}
                            />
                            <Route
                                exact
                                path="/join-project-refused/:id/:token"
                                element={<JoinProject isRefused={true} />}
                            />

                            <Route
                                exact
                                path="/profile"
                                element={
                                    <PrivateRoute>
                                        <Profile
                                            setCookiesPreferences={
                                                setCookiesPreferences
                                            }
                                        />
                                    </PrivateRoute>
                                }
                            ></Route>

                            <Route
                                exact
                                path="/my-account"
                                element={
                                    <PrivateRoute>
                                        <Account
                                            setCookiesPreferences={
                                                setCookiesPreferences
                                            }
                                        />
                                    </PrivateRoute>
                                }
                            ></Route>

                            <Route path="/viewer/:id">
                                <Route path=":bcfId" element={<Viewer />} />
                                <Route path="" element={<Viewer />} />
                            </Route>

                            <Route path="/viewer-embed/:id">
                                <Route
                                    path=":bcfId"
                                    element={<Viewer isEmbed={true} />}
                                />
                                <Route
                                    path=""
                                    element={<Viewer isEmbed={true} />}
                                />
                            </Route>

                            <Route path="/viewer-embed-config/:configId/:id">
                                <Route
                                    path=":bcfId"
                                    element={<Viewer isEmbed={true} />}
                                />
                                <Route
                                    path=""
                                    element={<Viewer isEmbed={true} />}
                                />
                            </Route>

                            <Route path="/document/:id">
                                <Route
                                    path=":commentId"
                                    element={<ViewDocument />}
                                />
                                <Route path="" element={<ViewDocument />} />
                            </Route>

                            <Route
                                exact
                                path="/bcf-callback"
                                element={
                                    <PrivateRoute>
                                        <OAuthCallback />
                                    </PrivateRoute>
                                }
                            ></Route>

                            <Route exact path="*" element={<PageNotFound />} />
                        </Routes>
                    )}

                    <ToastContainer
                        draggable={false}
                        style={{ zIndex: 99999 }}
                    />
                    {!Cookies.get("cookiesAccepted") &&
                        !Cookies.get("cookiesRefused") &&
                        !window.location.pathname.startsWith(
                            "/viewer-embed"
                        ) && (
                            <CookiesBanner
                                setCookiesPreferences={setCookiesPreferences}
                            />
                        )}
                </div>
            </DndProvider>

            {window.location.pathname !== "/register" &&
                !window.location.pathname.startsWith("/viewer") &&
                !window.location.pathname.startsWith("/document") && (
                    <IntercomChat />
                )}
        </Provider>
    );
}

export default App;
